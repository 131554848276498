// menu

var menuToggles, menuPopup;

menuToggles = document.querySelectorAll('.toggle-menu');
menuPopup = document.querySelector('#menu');

if (menuToggles) {
  Array.prototype.forEach.call(menuToggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return menuPopup.classList.toggle('active');
    });
  });
}

// main page video

var videoToggles, videoPopup;

videoToggles = document.querySelectorAll('.toggle-video');
videoPopup = document.querySelector('#video');

if (videoToggles) {
  Array.prototype.forEach.call(videoToggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return videoPopup.classList.toggle('active');
    });
  });
}

// home video popups

var videoPopup1, videoPopup1toggles, videoPopup2, videoPopup2toggles;

videoPopup1 = document.querySelector('#video-1');
videoPopup1toggles =  document.querySelectorAll('.toggle-video-1');
videoPopup2 = document.querySelector('#video-2');
videoPopup2toggles =  document.querySelectorAll('.toggle-video-2');

if (videoPopup1) {
  Array.prototype.forEach.call(videoPopup1toggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return videoPopup1.classList.toggle('active');
    });
  });
}

if (videoPopup2) {
  Array.prototype.forEach.call(videoPopup2toggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return videoPopup2.classList.toggle('active');
    });
  });
}

// smooth-scroll

import "./components/smooth-scroll.min";

var scroll = new SmoothScroll('a[href*="#"]', {
  offset: 70
} );
